import { NavLink } from "react-router-dom";
import "./Sidebar.css";

interface SidebarButtonProps {
  to: string;
  icon: React.ReactNode;
  iconActive: React.ReactNode;
  iconDark?: React.ReactNode;
  title: string;
  handleClick?: () => void;
}

const SidebarButton: React.FC<SidebarButtonProps> = ({
  to,
  icon,
  title,
  handleClick,
  iconDark,
  iconActive
}) => {
  return (
    <NavLink to={to} className="w-full flex md:block justify-center">
      {({ isActive }) => (
        <button
          onClick={() => handleClick && handleClick()}
          className={`${
            isActive
              ? "rounded w-20 md:mb-5 md:bg-[#F4F4F4] md:dark:bg-[#E3F2F2] text-[#168B3F]"
              : "md:w-[168px] w-20 md:justify-start md:mb-5 font-medium rounded dark:text-white text-black"
          } md:w-[168px] py-2 px-5 font-medium text-sm flex items-center justify-center md:justify-start transform duration-200 hover:scale-[1.1]`}
        >
          <span className="md:mr-[8px]">
            {isActive ? (
              iconActive
            ) : (
              <span className="dark:hidden">{icon}</span>
            )}
          </span>
          {!isActive && iconDark && (
            <span className="md:mr-[8px] dark:block hidden">{iconDark}</span>
          )}
          <span className="hidden md:block">{title}</span>
        </button>
      )}
    </NavLink>
  );
};

export default SidebarButton;
