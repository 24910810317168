import React from "react";
import cancel from "../../assets/cancelIcon.svg";
import lockIcon from "../../assets/lockIcon.svg";
import arrow from "../../assets/greaterThanArrowGreen.svg";
import arrowRightWhite from "../../assets/arrowRightWhite.svg";
import { useNavigate } from "react-router-dom";
import CwitoButton from "components/CwitoButton";

const SetPinModal = () => {
  const navigate = useNavigate();
  const goToSetPin = () => {
    navigate("/set-pin");
  };
  return (
    <div className="md:max-w-[70%] w-[90%]   bg-[#F8F8F8] p-6 flex flex-col gap-2 rounded-2xl shadow-xl">
      <div className="w-full flex justify-end items-center">
        <button className="p-1">
          <img src={cancel} height={12} width={12} alt="close icon" />
        </button>
      </div>

      <div className="w-full gap-3 p items-center justify-between flex-col flex">
        <img src={lockIcon} alt="survey icon" />
        <p
       
          className=" flex justify-center gap-2 items-center font-semibold "
        >
          Set Transaction Pin
         
        </p>
        <p className="mb-1  text-center text-xs text-[#00000080]">
          Keep your transactions safe and quick. Just a few taps to secure your
          account!.
        </p>
      </div>
      <div className="w-full flex justify-center">
        <CwitoButton
          color="bg-primary"
          title="Create Pin"
          width="w-[90%]"
          onClick={goToSetPin}
        />
      </div>
    </div>
  );
};

export default SetPinModal;
