import React from "react";
import cancel from "../../assets/cancelIcon.svg";
import surveyIcon from "../../assets/surveyIcon.svg";
import arrowRightWhite from "../../assets/arrowRightWhite.svg";
import { useNavigate } from "react-router-dom";
import { useAuth } from "configuration/AuthContext";

const SurveyModal = () => {
  const { setSurveyIsSubmitted } = useAuth();
  const navigate = useNavigate();
  const handleSurveyClick = () => {
    navigate("/survey");
  };

  const handleClick = () => {
    setSurveyIsSubmitted(true);
  };

  return (
    <div className=" w-[90%] md:w-full mx-auto relative border border-[#9BD1D0B2] bg-[#EAFEFF] px-20 py-3 flex flex-col gap-6 rounded-2xl shadow-xl">
      <div className="w-full  p items-start ">
        <p className="text-sm whitespace-nowrap text-start text-primary">
          What do you think of Cwito?
        </p>
        <button className="p-1 absolute top-2 right-2 " onClick={handleClick}>
          <img src={cancel} width={8} alt="close icon" />
        </button>
      </div>
      <div className="w-full flex justify-center items-center">
        <button
          onClick={handleSurveyClick}
          className="border border-primary text-xs font-medium py-2  px-4 rounded-[32px] flex justify-center items-center"
        >
          Share feedback
        </button>
      </div>
    </div>
  );
};

export default SurveyModal;
