import React from "react";
import loadingSpinner from "../assets/LodaingWhite.svg";
interface CwitoButtonProps {
  title: string;
  color: string;
  onClick: any;
  width: string;
  disabled?: boolean;
  isLoading?: boolean;
}
const CwitoButton: React.FC<CwitoButtonProps> = ({
  title,
  color,
  onClick,
  width,
  disabled,
  isLoading,
}) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`min-h-[11] ${color} dark:bg-buttonDark  ${width} ${
        disabled ? "opacity-50 cursor-not-allowed" : "hover:bg-[#03464E]"
      }  px-2 py-3 flex justify-center items-center rounded-md text-white dark:text-primary text-sm font-semibold text-center  gap-1`}
    >
      {isLoading ? (
        <img
          height={20}
          width={20}
          className="suspense-loading-icon"
          src={loadingSpinner}
          alt="loading spinner"
        />
      ) : (
        title
      )}
    </button>
  );
};

export default CwitoButton;
